<template>
  <section :class="`section request-section  ${props.color === 'dark' ? 'dark-bg' : 'light-bg'}`">
    <span id="request" class="hash-elem"></span>
    <div class="container">
      <div class="request">
        <div class="request__info">
          <h2 :class="`title request__title ${props.color === 'dark' ? 'title--invert' : ''}`">Обсудим проект?</h2>
          <p :class="`text request__text ${props.color === 'dark' ? '' : 'text--dark'}`">
            Найдем лучшее IT-решение для вашего бизнеса. Напишите нам, чтобы начать работу над проектом
          </p>
        </div>

        <div v-if="!isFormSend" class="request__form-container">
          <form class="request__form" @submit.prevent="handleRequestForm" name="requestForm" id="requestForm">
            <input
              v-model="state.name"
              type="text"
              placeholder="Имя"
              :class="`request__input text-input text-input--accent ${props.color === 'dark' ? 'text' : ''}`"
              @blur="v.name.$touch"
            />
            <span v-if="v.name.$error" class="error-message">
              {{ v.name.$errors[0]?.$message }}
            </span>
            <input
              v-model="state.email"
              type="text"
              placeholder="Email"
              :class="`request__input text-input text-input--accent ${props.color === 'dark' ? 'text' : ''}`"
              @blur="v.email.$touch"
            />
            <span v-if="v.email.$error" class="error-message">
              {{ v.email.$errors[0]?.$message }}
            </span>
            <input
              v-model="state.phone"
              type="text"
              placeholder="Телефон"
              :class="`request__input text-input text-input--accent ${props.color === 'dark' ? 'text' : ''}`"
              @blur="v.phone.$touch"
            />
            <span v-if="v.phone.$error" class="error-message">
              {{ v.phone.$errors[0]?.$message }}
            </span>
            <span v-if="errorMessage" class="error colored">
              {{ errorMessage }}
            </span>
            <button v-if="!isProcessActive" type="submit" class="button gradient-bg request__btn">Отправить заявку</button>
          </form>
        </div>
        <div v-else class="request__form-container">
          <span class="success">
            {{ 'Сообщение отправлено, мы обязательно свяжемся с вами!' }}
          </span>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useMail } from '#imports';
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import { PAGES } from '~/components/const';

interface IProps {
  color?: 'dark' | 'light';
  formName?: 'logistics' | 'manufacturing' | 'main'; //  for yandex.metrika
}

const props = defineProps<IProps>();
const config = useRuntimeConfig();

interface IState {
  name: string;
  email: string;
  phone: string;
}

const state = reactive<IState>({
  name: '',
  email: '',
  phone: '',
});

const emailRegex = /^(?=.{1,320}$)[a-zA-Z][\w.%+-]+@[\w.-]+\.[a-zA-Z]{2,6}$/;
const phoneRegEx = /^(8|\+7)\d{10}$/;
const emailValidate = (value: string) => emailRegex.test(value);
const phoneValidator = (value: string) => phoneRegEx.test(value);

const rules = computed(() => {
  return {
    name: {
      required: helpers.withMessage('Обязательное поле', required),
      minLength: 2,
    },
    email: {
      required: helpers.withMessage('Обязательное поле', required),
      email: helpers.withMessage('Неверный формат поля email', emailValidate),
    },
    phone: {
      required: helpers.withMessage('Обязательное поле', required),
      phoneValidator: helpers.withMessage('Формат номера: +70000000000 ', phoneValidator),
    },
  };
});

const v = useVuelidate(rules, state);
const errorMessage = ref<string>('');
const isProcessActive = ref<boolean>(false);
const isFormSend = ref<boolean>(false);
const handleRequestForm = async () => {
  const isFormValid = await v.value.$validate();
  if (isFormValid) {
    try {
      isProcessActive.value = true;
      const mail = useMail();
      await mail.send({
        from: 'no-replay@sales-area.ru',
        subject: 'Заявка с сайта terpo.ru',
        html: `
      <html>
          <head>
              <meta charset='utf-8'>
              <style>
                  a {
                    text-decoration: none;
                    color: #409eff;
                  }
                  a:visited {
                      color: #409eff;
                  }

                  a.button:visited {
                      color: #ffffff;
                  }
              </style>
            </head>
            <body>
            <center>
              <div style="width: 600px;">
                <table border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width:280px">
                  <tbody>
                  <tr>
                    <td height="24">
                      <div style="font-size:24px;height:24px;line-height:24px">
                        &nbsp;
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td align="center">
                      <table align="center" border="0" cellpadding="0" cellspacing="0" height="52" width="100%"
                             style="background-color:#67c23a;border-radius:16px;color:#ffffff">
                        <tbody>
                        <tr>
                          <td align="left" valign="middle">
                            <table border="0" cellpadding="0" cellspacing="0" style="padding:0 16px 0 16px">
                              <tbody>
                              <tr>
                                <td align="left" valign="middle">
                                  <a href="https://terpo.ru"
                                     rel="noopener noreferrer"
                                     target="_blank"
                                     data-link-id="24"
                                     style="color:#ffffff;
                                     font-family:'arial', 'helvetica', sans-serif;
                                     font-size:18px;
                                     line-height:18px;
                                     text-align:center;
                                     text-decoration:none"
                                     >
                                      TERPO
                                  </a>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td height="24">
                      <div style="font-size:24px;height:24px;line-height:24px">
                        &nbsp;
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <table border="0" cellpadding="0" cellspacing="0" width="100%"
                             style="background-color:#ffffff;border-radius:16px">
                        <tbody>
                        <tr>
                          <td align="center">
                            <table border="0" cellpadding="0" cellspacing="0" width="90%">
                              <tbody>
                              <tr>
                                <td height="24">
                                  <div style="font-size:24px;height:24px;line-height:24px"> &nbsp;</div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <table border="0" cellpadding="0" cellspacing="0" width="100%">
                                    <tbody>
                                    <tr>
                                      <td></td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                    </tr>
                                    <tr>
                                      <td align="left" valign="top">
                                        <div
                                            style="color:#262626;font-family:'arial' , 'helvetica' , 'tahoma' , sans-serif;font-size:22px;font-weight:bold;line-height:26px;margin-bottom:8px">
                                          Заявка с сайта terpo.ru! ${props.formName ? 'Раздел' + PAGES[props.formName] : ''}
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td align="left" valign="top">
                                        <div
                                            style="color:#262626;font-family:'arial' , 'helvetica' , 'tahoma' , sans-serif;font-size:16px;line-height:20px;max-width:536px">
                                          <br>
                                          Имя: ${state.name}<br>
                                          Email: ${state.email}<br>
                                          Телефон: ${state.phone}
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td height="8">
                                        <div style="font-size:8px;height:8px;line-height:8px"> &nbsp;</div>
                                      </td>
                                    </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td height="24">
                                  <div style="font-size:24px;height:24px;line-height:24px"> &nbsp;</div>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td align="center">
                            <table border="0" cellpadding="0" cellspacing="0" width="90%">
                              <tbody>
                              <tr>
                                <td height="1">
                                  <div
                                      style="border-bottom-color:#ececec;border-bottom-style:solid;border-bottom-width:1px;height:1px;line-height:1px">
                                    &nbsp;
                                  </div>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td height="24">
                      <div style="font-size:24px;height:24px;line-height:24px">
                        &nbsp;
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td height="16">
                      <div style="font-size:16px;height:16px;line-height:16px"> &nbsp;</div>
                    </td>
                  </tr>
                  </tbody>
                </table>
            </div>
          </center>
      </body>
      </html>
      `,
      });
      isFormSend.value = true;
      isProcessActive.value = false;
      if (props.formName && config?.public?.nuxtMode === 'production') {
        ym(96342723, 'reachGoal', props.formName);
      }
    } catch {
      isProcessActive.value = false;
      errorMessage.value = 'Что-то пошло не так. Попробуйте позже.';
    }
  }
};
</script>

<style scoped lang="scss">
.request {
  padding-right: 50%;
  background-image: image-set(url('/img/index/chat.webp') type('image/webp'), url('/img/index/chat.png') type('image/png'));
  background-repeat: no-repeat;
  background-size: 50%, auto, contain;
  background-position: center right;
  min-height: 466px;

  &__hash {
    position: absolute;
    font-size: 0;
    top: -200px;
    left: 0;
  }

  &__title {
    margin-bottom: 36px;
  }

  &__text {
    margin-bottom: 30px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 17px;
    align-items: flex-start;
  }

  &__input {
    width: 100%;
    max-width: 100%;
  }
}

.error-message {
  color: var(--accent-color);
  font-weight: var(--medium);
  font-size: var(--article-text);
  line-height: var(-article-line);
}

.error {
  color: var(--accent-color);
  font-weight: var(--medium);
  text-align: center;
  font-size: var(--article-text);
  line-height: var(-article-line);
}

.success {
  text-align: center;
  color: var(--accent-color);
  font-weight: var(--medium);
  font-size: var(--standard-text);
  line-height: var(--standard-line);
}

@media (max-width: 767px) {
  .request {
    background-image: none;
    padding-right: 0;
    width: 500px;
    max-width: 100%;
    margin: 0 auto;
  }
}
</style>
